Platform.app = Backbone.Router.extend({

  routes: {
    'accounts/mailboxes': 'uploadMailboxes'
  },

  initialize: function() {
    this.views = this.views || {};
    this.setupGlobalObservers();
    Backbone.history.start({pushState: true});
  },

  // Callbacks

  uploadMailboxes: function() {

    var revokeConfirmable = new DocumentCloud.views.Confirmable({
      confirm:       Backbone.$.proxy(function(){
        Backbone.$.getPopover().$trigger.closest('form').trigger('submit.revoke');
      }, this),
      message:       'Are you sure you want to revoke this mailbox?',
      confirmButton: 'Yes, revoke mailbox',
      denyButton:    'No, leave mailbox alone',
      confirmColor:  'btn-danger',
      denyColor:     '',
    });

    $('.mailbox-revoke').popover({
      html:      true,
      placement: 'bottom',
      content:   revokeConfirmable.el,
    }).closest('form').on('submit', function(e) {
      e.preventDefault();
    });

  },

  // Utilities

  setupGlobalObservers: function() {
    this._managePopovers();
  },

  _managePopovers: function() {
    Backbone.$(document).on('show.bs.popover hide.bs.tab', function() {
      // Close any open currently-open popovers when:
      // 1. Opening a popover
      // 2. Switching a tab
      Backbone.$(this).closePopover();
    }).on('click.popover', '.popover-closer', function(e) {
      // Close any open currently-open popovers when clicking a popover closer
      Backbone.$(this).closePopover();
    }).on('shown.bs.popover', function(e) {
      // Make sure autofocus happens every time it's supposed to.
      Backbone.$(e.target).getPopover().$popover.autofocus();
    });
  },

});

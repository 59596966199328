DocumentCloud.views.Confirmable = Backbone.View.extend({

  events: {
    'click.confirmable .confirm': 'confirm',
    'click.confirmable .deny':    'deny',
  },
  
  template: DocumentCloud.templates['confirmable'],

  initialize: function(options) {
    this.options = _.defaults(options, {
      message:       'Are you sure you want to continue?',
      confirmButton: 'Yes, continue',
      denyButton:    'No, cancel',
      confirmColor:  'btn-success',
      denyColor:     'btn-danger',
    });
    this.render();
  },

  render: function() {
    this.$el.html(this.template(this.options));
    return this;
  },

  confirm: function() {
    if (_.isFunction(this.options['confirm'])) {
      this.options['confirm'](this);
    }
  },

  deny: function() {
    if (_.isFunction(this.options['deny'])) {
      this.options['deny'](this);
    }
  },

});

(function($) {

  $.extend({
    getPopover: function() {
      return $(document.body).getPopover();
    }
  });

  $.fn.extend({

    // Toggle the contents of an element.
    // Usage: `$(element).toggleContents([string1, [string2, [opts]]])
    // - If `string1` and `string2` args, toggle between them
    // - If only `string1` arg, toggle between it and call-time element contents
    // - If no string args but yes `data-toggle-contents` attr, toggle between 
    //   attr value and call-time contents
    // - If no string args and no `data-toggle-contents` attr, no toggling
    // - If `opts.html` is true, use jQuery's `html` method for reading and 
    //   modifying contents instead of `text` (defaults false)
    toggleContents: function() {
      var $this    = $(this).first();

      var opts = _.isObject(arguments[2]) ? arguments[2] : (_.isObject(arguments[1]) ? arguments[1] : (_.isObject(arguments[0]) ? arguments[0] : {}));
      opts = _.defaults(opts, {
        html: false,
      });
      var method         = opts.html === true ? 'html' : 'text';
      var currentContent = $this[method]();

      var toggle_contents_one, toggle_contents_other;
      if ($this.data('toggle-contents-init')) {
        toggle_contents_one   = $this.data('toggle-contents-one');
        toggle_contents_other = $this.data('toggle-contents-other');
      } else {
        if (_.isString(arguments[1])) {
          toggle_contents_one   = arguments[0];
          toggle_contents_other = arguments[1];
        } else {
          toggle_contents_one   = currentContent;
          if (_.isString(arguments[0])) {
            toggle_contents_other = arguments[0];
          } else if ($this.data('toggle-contents')) {
            toggle_contents_other = $this.data('toggle-contents');
          } else {
            return this;
          }
        } 
      }

      $this.data({
        'toggle-contents-init':  true,
        'toggle-contents-one':   toggle_contents_one,
        'toggle-contents-other': toggle_contents_other,
      });

      $this[method](currentContent == toggle_contents_other ? toggle_contents_one : toggle_contents_other);

      return this;
    },
    // Alias `toggleContents`
    toggleContent: function() {
      $(this).toggleContents.apply(this, arguments);
      return this;
    },

    // A jQuery-object-version of `Element.scrollIntoView`. If the browser 
    // supports `scrollIntoView`, uses it, else uses jQuery's `animate`.
    scrollIntoView: function(opts) {
      opts = _.defaults(opts || {}, {
        behavior: 'smooth',
        padding:  0,
        speed:    500,
      });

      var $this  = $(this).first();
      var native = $this.get(0);

      if (_.isFunction(native.scrollIntoView)) {
        native.scrollIntoView(opts);
      } else {
        var scrollTop = $this.offset().top - opts.padding;
        $('html, body').animate({
          scrollTop: scrollTop
        }, opts.speed);
      }

      return this;
    },

    // Bootstrap's Popovers are funny things. There are two basic components:
    // - the `trigger`, aka the button you clicked to spawn a popover;
    // - the `popover`, aka the actual popover that appears and contains stuff.
    // You setup a popover by calling `popover()` on the trigger, like so:
    //   `$('.popover-button').popover();`
    // This creates a `bs.popover` data attribute on the trigger. When you click the trigger and open the popover, the resulting popover element has its own copy of this object in its own `bs.popover` data attribute. This object lets you access both the trigger and the popover DOM elements themselves, but they have terrible names: `element` is the trigger and `tip` is the popover. This is hard to remember and hard to interact with.
    // 
    // `getPopover()` makes sense of the madness with a single, sensible interface for finding a given popover and trigger. You can call it on any element at any time that might have an associated popover. It asks:
    // 1. Am I a popover or trigger? If so, I'm the context. If not...
    // 2. Am I the child of a popover? If so, that's the context. If not...
    // 3. Do I contain an OPEN popover? If so, that's the context. If not...
    // 4. Is there ANY open popover? If so, that's the context.
    // It either returns `false` (if it can't find a context) or it examines 
    // the context and returns a sensible object: `data` is the original 
    // `bs.popover`, `popover` is the popover element, `trigger` is the trigger 
    // element, and then `$popover`/`$trigger` are jQuery-extended versions of 
    // those elements. You're welcome.
    getPopover: function() {
      var $this = $(this);
      var popover = $this.data('bs.popover')
          || $this.closest('.popover').data('bs.popover')
          || $this.find('.popover.in').data('bs.popover')
          || $('.popover.in').first().data('bs.popover');
      if (popover) {
        return {
          data:     popover,
          popover:  popover.tip,
          trigger:  popover.element,
          $popover: $(popover.tip),
          $trigger: $(popover.element),
        }
      } else {
        return false;
      }
    },

    // If called on an element that has a related popover (see `getPopover() 
    // for how this is discovered), closes it. If `getPopover()` returns an 
    // orphaned popover (i.e., the trigger has been removed from the DOM), then 
    // it removes it.
    closePopover: function() {
      var popover = $(this).getPopover();
      if (popover) {
        if ($.contains(document.body, popover.trigger)) {
          // https://github.com/twbs/bootstrap/issues/16732#issuecomment-232138662
          if (!popover.$trigger.data('cleanup-ready')) {
            popover.$trigger.data('cleanup-ready', true).on('hidden.bs.popover', function (e) {
              $(e.target).data('bs.popover')._activeTrigger.click = false;
            });
          }

          // The trigger is still in-DOM, so let's hide it.
          popover.$trigger.popover('hide');
        } else {
          // The trigger isn't in the DOM, so this popover body is orphaned. 
          // Remove it.
          popover.$popover.remove();
        }
      }
      return this;
    },

    showProgress: function(opts) {
      opts = _.defaults(opts || {}, {
        global:   true,
        value:    100,
        max:      100,
        message:  false,
        striped:  true,
        animated: true,
      });

      // Whitelist position values
      var position = _.contains(['prepend', 'append'], opts.position) ? opts.position : 'prepend';

      var progress = '';
      if (_.isString(opts.message)) {
        progress += '<div class="text-xs-center">' + opts.message + '</div>';
      }
      var classNames = ['progress', 'progress-generated'];
      if (opts.striped) { classNames.push('progress-striped'); }
      if (opts.animated) { classNames.push('progress-animated'); }
      progress += '<progress class="' + classNames.join(' ') + '" value="' + opts.value + '" max="' + opts.max + '"></progress>';

      var $this     = $(this);
      var $progress = $(progress);

      if (opts.global) {
        $progress.addClass('progress-global')[position + 'To']('body');
      } else {
        $progress[position + 'To']($this);
      }

      $this.data('progress-bar', $progress);
      return this;
    },

    hideProgress: function() {
      var $this = $(this);
      var $progress = $this.data('progress-bar')
                      || ($(this).find('.progress-generated').length ? $(this).find('.progress-generated') : false)
                      || $('body').data('progress-bar')
                      || $('body').find('.progress-generated');
      $this.removeData('progress-bar');
      $progress.remove();
      return this;
    },

    // Am I a collection of input elements? Return me! Otherwise, find inputs 
    // inside of me and return them.
    findInputs: function() {
      var $this = $(this);
      var formFieldSelectors = 'input, button, select, textarea';
      if ($this.is(formFieldSelectors)) {
        return $this;
      } else {
        return $this.find(formFieldSelectors);
      }
    },

    // Simple way of preventing multiple submits from a form. Currently only 
    // designed to work with non-Ajax submits.
    blockMultipleSubmits: function() {
      $(this).on('submit', function(e) {
        if ($(this).data('submitted') === true) {
          e.preventDefault();
        } else {
          $(this).data('submitted', true).find('button[type=submit]').disable();
        }
      });
      return this;
    },

    // Blindly enable all input elements.
    enable: function() {
      $(this).findInputs().each(function() {
        var $input = $(this);
        $input.prop('disabled', false);
      });
      return this;
    },

    // For undoing `disable()`: rather than blindly removing any `disabled` 
    // properties, see if we have previously stored an original `disabled` 
    // state on the input element, and return it to that.
    reenable: function() {
      $(this).findInputs().each(function() {
        var $input = $(this);
        $input.prop('disabled', _.isUndefined($input.data('original-disabled')) ? false : $input.data('original-disabled'));
      });
      return this;
    },

    // Blindly disable all input elements, but first: store its original 
    // disabled state on a data element for retrieval by `reenable()`;
    disable: function() {
      $(this).findInputs().each(function() {
        var $input = $(this);
        $input.data('original-disabled', $input.data('original-disabled') || $input.prop('disabled')).prop('disabled', true);
      });
      return this;
    },

    // In certain scenarios (like HTML rendered in a Bootstrap popover), the 
    // autofocus doesn't happen or doesn't happen consistently.
    autofocus: function() {
      $(this).find('[autofocus]').first().trigger('focus');
    },

  });

})(Backbone.$);

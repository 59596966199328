//==============================================================================
// OVERRIDES
//==============================================================================

// Overriding default `Backbone.sync`.
// Rails wants us to send model data wrapped in a model class name, like this:
//
//   { membership: {id: 1, name: 'Foo'} }
//
// This version of `sync` looks for a `wrapper` attribute on the class and, 
// if it exists, wraps the model's data before sending. Changes consist of:
//   1. Inlining `var methodMap` since, in Backbone proper, that's outside of 
//      the `sync` function for some reason;
//   2. The OVERRIDE START ... OVERRIDE END block
Backbone.sync = function(method, model, options) {
  var methodMap = {
    'create': 'POST',
    'update': 'PUT',
    'patch':  'PATCH',
    'delete': 'DELETE',
    'read':   'GET'
  };

  var type = methodMap[method];

  // Default options, unless specified.
  _.defaults(options || (options = {}), {
    emulateHTTP: Backbone.emulateHTTP,
    emulateJSON: Backbone.emulateJSON
  });

  // Default JSON-request options.
  var params = {type: type, dataType: 'json'};

  // Ensure that we have a URL.
  if (!options.url) {
    params.url = _.result(model, 'url') || urlError();
  }

  // Ensure that we have the appropriate request data.
  if (options.data == null && model && (method === 'create' || method === 'update' || method === 'patch')) {
    params.contentType = 'application/json';

    // OVERRIDE START
    var data = options.attrs || model.toJSON(options);
    if (model.constructor.wrapper) {
      var wrappedData = {};
      wrappedData[model.constructor.wrapper] = data;
      params.data = JSON.stringify(wrappedData);
    } else {
      params.data = JSON.stringify(data);
    }
    // OVERRIDE END
  }

  // For older servers, emulate JSON by encoding the request into an HTML-form.
  if (options.emulateJSON) {
    params.contentType = 'application/x-www-form-urlencoded';
    params.data = params.data ? {model: params.data} : {};
  }

  // For older servers, emulate HTTP by mimicking the HTTP method with `_method`
  // And an `X-HTTP-Method-Override` header.
  if (options.emulateHTTP && (type === 'PUT' || type === 'DELETE' || type === 'PATCH')) {
    params.type = 'POST';
    if (options.emulateJSON) params.data._method = type;
    var beforeSend = options.beforeSend;
    options.beforeSend = function(xhr) {
      xhr.setRequestHeader('X-HTTP-Method-Override', type);
      if (beforeSend) return beforeSend.apply(this, arguments);
    };
  }

  // Don't process data on a non-GET request.
  if (params.type !== 'GET' && !options.emulateJSON) {
    params.processData = false;
  }

  // Pass along `textStatus` and `errorThrown` from jQuery.
  var error = options.error;
  options.error = function(xhr, textStatus, errorThrown) {
    options.textStatus = textStatus;
    options.errorThrown = errorThrown;
    if (error) error.call(options.context, xhr, textStatus, errorThrown);
  };

  // Make the request, allowing the user to override any Ajax options.
  var xhr = options.xhr = Backbone.ajax(_.extend(params, options));
  model.trigger('request', model, xhr, options);
  return xhr;
};


//==============================================================================
// EXTENSIONS
//==============================================================================

// Given a `childReferences` object property on the view, this will lookup and 
// cache the child elements on a `childEls` object property. Example:
//
//   childReferences: {
//     $form:  '.form-member-invite',
//     $email: 'input[name=email]',
//   }
//
// Results in `view.childEls.$form` and `view.childEls.$email`.
Backbone.View.prototype.cacheChildReferences = function() {
  if (!_.isObject(this.childReferences)) { return; }
  this.childEls = this.childEls || {};

  var _this = this;
  _.each(this.childReferences, function(selector, key) {
    _this.childEls[key] = _this.$(selector);
  });
};

// Given a `permissionRules` object property on the view, this will check the 
// root HTML element for classes matching the value (selector) and assign its 
// boolean result to the key. Example:
//
//   <html lang="en" class="role-owner can-manage-memberships">
//
//   permissionRules: {
//     canManageMemberships: 'can-manage-memberships',
//   }
//
// Results in `view.permissions.canManageMemberships === true`
Backbone.View.prototype.cachePermissions = function() {
  if (!_.isObject(this.permissionRules)) { return; }
  this.permissions = this.permissions || {};

  var _this = this;
  _.each(this.permissionRules, function(className, key) {
    _this.permissions[key] = Backbone.$('html').hasClass(className);
  });
};
